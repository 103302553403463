<template>
  <div v-loading="rightLoading">
    <div class="button-body">
      <RadioGroup v-model="buttonType" type="button" @on-change="changeValue" button-style="solid"
        style="margin-top: 16px">

        <Radio v-for="item in list" :key="item.title" :label="item.title">
          <Dropdown v-if="!item.isTarget" @on-click="onAllSelected">
            {{ item.title }}
            <Icon type="md-arrow-dropdown" />
            <DropdownMenu slot="list">
              <DropdownItem :name="chid.title" :selected="chid.selected" v-for="chid in item.children"
                :key="chid.title">
                {{ chid.title }}
                <Icon v-show="chid.selected" type="md-checkmark" />
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </Radio>
      </RadioGroup>
    </div>
    <RadioGroup v-model="dateType" type="button" @on-change="changeDateType" button-style="solid"
      style="margin-top: 16px;margin-left: 32px;">
      <Radio :label="item" v-for="(item, index) in dateTypeList" :key="index">
      </Radio>
      <!-- <Radio :label="item"  v-for="(item,index) in dateTypeList" :key="index" :style="[{borderRadius:(index==0?'20px 0 0 20px':'0 20px 20px 0')}]" >
            </Radio> -->
      <!-- <Radio :label="item"  v-for="item in dateTypeList" :key="item" style="border-radius: 20px 0 0 20px;" >
            </Radio> -->
      <!-- <Radio label="年度"  style="border-radius: 0 20px 20px 0;"  ></Radio> -->
    </RadioGroup>
    <!-- <div>
    <Menu mode="horizontal"  active-title="3">
        <MenuItem title="1" name="1">
            内容管理
        </MenuItem>
        <MenuItem name="2">
            用户管理
        </MenuItem>
        <Submenu name="3">
            <template slot="title">
                统计分析
            </template>
            <MenuGroup >
                <MenuItem title="3-1">新增和启动</MenuItem>
                <MenuItem title="3-2">活跃分析</MenuItem>
                <MenuItem title="3-3">时段分析</MenuItem>
            </MenuGroup>

        </Submenu>
        <MenuItem title="4">
            综合设置
        </MenuItem>
    </Menu>
    </div> -->
  </div>
</template>

<script>
import {
  getTagTree
} from "@/api/mapView";

export default {
  props: {
    level: {
      type: String,

    },
    cityName: {
      type: String,
    },
    city:{
      type: String,

    },
  },
  data() {
    return {
    rightLoading: false,
      // tabRadioStyle:{
      //   borderRadius:'0 20px 20px 0'
      // },
      list: [
        {
          title: "GDP",
          timePeriod: ['季度', '年度'],
          children: [
            { title: "GDP", selected: false },
            { title: "人均GDP", selected: false, },
          ]
        },
        {
          title: "投资",
          timePeriod: ['季度', '年度'],
          children: [
            { title: "固定资产", selected: false },
            { title: "房地产", selected: false, },
            { title: "土地", selected: false },
            {
              title: "施工", selected: false,
            },
          ]
        },
        {
          title: "人口", type: "List",
          timePeriod: ['年度'],
          children: [
            { title: "常驻", selected: false },
            { title: "迁入", selected: false, },
          ]
        },
        {
          title: "工资",
          timePeriod: ['季度'],
          children: [
          ]
        },
        {
          title: "融资",
          timePeriod: ['季度', '年度'],
          children: [
          ]
        },
        {
          title: "货币",
          timePeriod: ['季度', '日度', '年度'],
          children: [
          ]
        },
      ],
      dateTypeList: [
        // '季度', '月度',
      ],
      indicator: [
        {
          index: "",
          target: ""
        }
      ],//指标组
      dateType: '',//季度-月度-年  等选择
      quarterList: [
      ],
      buttonType: '',
      gdpSelect: '',
      selectInvestment: '',
      selectPeople: '',
      areaType: 0,//类型
      areaName: '国家',//省份类型

    };
  },
  watch: {
    cityName(val) {//监听省-市-区-变化
      // if (val=='国家') {
      // this.areaType = 0
      // this.areaName = val
      // this.getTagTreeData()
      // }
         this.areaName  = val
  this.getTagTreeData()
    },
    level(val) {
      this.areaType = val
      this.areaName = this.cityName
      this.getTagTreeData()
    },
  },
  created() {

  },
  mounted() {
    this.getTagTreeData()
  },
  computed: {
    tabRadioStyle() {
      return {
        borderRadius: '0 20px 20px 0'
      }
      // if (index==0) {
      //  return {
      //       borderRadius:'0 20px 20px 0'
      //     }
      // }

    },
  },
  methods: {
    async getTagTreeData() {//获取菜单树
      let params = {
        areaType: this.areaType,
        areaName: this.areaName,
        // country:'国家',
        // province:'',//省份
        //  city:''//城市
      }
      this.rightLoading = true
      let res = await getTagTree(params)
      this.rightLoading = false
      this.list = res.tagTree

    if (!res.tagTree) {
     this.buttonType = []
       this.dateTypeList = []
      this.$emit('emptyEcharts');
      return
      }

      this.buttonType = this.list[0].title
      this.dateTypeList = this.list[0].timePeriod
      this.dateType = this.dateTypeList[0]
      let obj = {
        index: "",
        target: ""
      }
      this.indicator = []
      this.list[0].children.map(item => {
        obj = {
          index: item.index,
          target: item.title
        }
        this.indicator.push(obj)
      })
      if (!this.list[0].isTarget ) {
        this.onAllSelected(this.list[0].children[0].title)
        // return
      }
      this.$emit('changeDateType', this.dateType, this.indicator);

    },
    changeDateType(title) {//季度-月度-年度 等切换
      this.$emit('changeDateType', this.dateType, this.indicator);
    },


    onAllSelected(title) {//列表选中
      let obj = {
        index: "",
        target: ""
      }
      this.list.map(item => {
        item.children.map(child => {
          if (child.title == title) {
            child.selected = true
            this.dateTypeList = child.timePeriod
            this.dateType = this.dateTypeList[0]
            obj.index = child.children[0].index
            obj.target = child.children[0].title
          } else {
            child.selected = false
          }
        })
      })
      this.indicator = [obj]

      this.$emit('changeDateType', this.dateType, this.indicator);

    },
    getTagTree() {//从后台获取数据
    },

    handleArrays(title) {//数组的一些处理
      for (let index = 0; index < this.list.length; index++) {
        if (title === this.list[index].title && this.list[index].children.length > 0) {//这是有二级分类的
          let isSelected = this.list[index].children.some(item => item.selected);//判断是否已经有选中的值
          if (!isSelected) {
            this.list.map(item => {//这是将所有其他选项置为false
              if (item.children.length > 0) {
                item.children.map(child => {
                  child.selected = false;
                })
              }
            })
            let obj = {
              index: "",
              target: ""
            }
            let obj2 = {
              index: "",
              target: ""
            }
            if (this.list[index].isTarget) {
              obj.index = this.list[index].children[0].index;
              obj.target = this.list[index].children[0].title;

            } else {
               obj.index = this.list[index].children[0].children[0].index
                obj.target = this.list[index].children[0].children[0].title
            }
            this.indicator = [obj]
            if (this.list[index].title == '融资') {
              obj2.index = this.list[index].children[1].index;
              obj2.target = this.list[index].children[1].title;
              this.indicator.push(obj2)
            }
            this.dateTypeList = this.list[index].timePeriod
            this.dateType = this.dateTypeList[0]
            if (this.dateTypeList.length == 0) {//这是子集没有timePeriod的时候
              this.dateTypeList = this.list[0].children[0].timePeriod
              this.dateType = this.dateTypeList[0]
              // if (this.list[index].isTarget) {
              //   obj.index = this.list[index].children[0].index
              //   obj.target = this.list[index].children[0].title
              // } else {
              //   obj.index = this.list[index].children[0].children[0].index
              //   obj.target = this.list[index].children[0].children[0].title
              // }

              // this.indicator = [obj]
            }

            this.$emit('changeDateType', this.dateType, this.indicator);
            this.list[index].children[0].selected = true;

          }
        }



      }
    },
    changeValue(title) {//整个buttonvalue切换
      let isTowLevel = this.list.some(item => item.title == title && item.children.length > 0);
      if (isTowLevel) {
        this.handleArrays(title);
      } else {
        this.list.map(item => {
          if (item.title == title) {
            this.dateTypeList = item.timePeriod
            this.dateType = this.dateTypeList[0]
            this.indicator = item.title
            this.$emit('changeDateType', this.dateType, this.indicator);
          }
          if (item.children.length > 0) {
            item.children.map(child => {
              child.selected = false;//这是将所有其他选项置为false
            })
          }
        })
      }

    }
  }
};
</script>

<style scoped lang="scss">
.button-body {
  display: flex;
  justify-content: center;
}

.firstTab {
  border-radius: 20px 0 0 20px;
}
</style>
