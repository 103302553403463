<template>
  <div>

    <div class="map-body">
      <div class="title">{{ chinaName }}</div>
      <div class="map-flex">
        <div class="map-box"  v-loading="mapLoading">
          <div :id="echartsId" v-show="isShowChinaMap" class="echartsMap" @mouseleave="mouseleave"></div>
          <ProvinceMap @back="goback" @clickCity="clickCity" @changeLeave="changeLeave" :provinceNames="provinceName" v-if="!isShowChinaMap">
          </ProvinceMap>
        </div>
        <div class="echatts-box">
          <!-- <div :id="echartsHistogramId" v-show="isShowChinaMap" class="echartsHistogram"> </div> -->
      <BarAndLing
      :cityName="titleName"
      :level="levelFathser"
      ></BarAndLing>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
const echarts = require("echarts");
	import _ from 'lodash'

import chinaJson from "echarts/map/json/china.json";
import ProvinceMap from '@/views/economics/components/ProvinceMap'
import BarAndLing from '@/views/economics/components/barlineEcharts/BarAndLing'
import {
  postList
} from "@/api/mapView";

export default {

  components: {

    ProvinceMap,
    BarAndLing,

  },
  data() {
    return {
      mapLoading: false,
      titleName: '国家',
      levelFathser:0,
      provinceName: '',
      isShowChinaMap: true,
      echartsId: 'echarts_' + new Date().getTime() + Math.floor(Math.random() * 1000),
      echartsHistogramId: 'histogram_' + new Date().getTime() + Math.floor(Math.random() * 1000),
      dataList: [
        { name: "南海诸岛" },
        { ename: "beijing", name: "北京" },
        { ename: "tianjin", name: "天津" },
        { ename: "shanghai", name: "上海" },
        { ename: "chongqing", name: "重庆" },
        { ename: "hebei", name: "河北" },
        { ename: "henan", name: "河南" },
        { ename: "yunnan", name: "云南" },
        { ename: "liaoning", name: "辽宁" },
        { ename: "heilongjiang", name: "黑龙江" },
        { ename: "hunan", name: "湖南" },
        { ename: "anhui", name: "安徽" },
        { ename: "shandong", name: "山东" },
        { ename: "xinjiang", name: "新疆" },
        { ename: "jiangsu", name: "江苏" },
        { ename: "zhejiang", name: "浙江" },
        { ename: "jiangxi", name: "江西" },
        { ename: "hubei", name: "湖北" },
        { ename: "guangxi", name: "广西" },
        { ename: "gansu", name: "甘肃" },
        { ename: "shanxi", name: "山西" },
        { ename: "neimenggu", name: "内蒙古" },
        { ename: "shanxi1", name: "陕西" },
        { ename: "jilin", name: "吉林" },
        { ename: "fujian", name: "福建" },
        { ename: "guizhou", name: "贵州" },
        { ename: "guangdong", name: "广东" },
        { ename: "qinghai", name: "青海" },
        { ename: "xizang", name: "西藏" },
        { ename: "sichuan", name: "四川" },
        { ename: "ningxia", name: "宁夏" },
        { ename: "hainan", name: "海南" },
        { name: "台湾" },
        { ename: "xianggang", name: "香港" },
        { ename: "aomen", name: "澳门" },
      ],
    };
  },
  computed:{
    chinaName(){
    // return '22222'
    if (this.titleName=='国家') {
      return '全国'
    }else{
      return this.titleName

    }
    },
  },
  beforeDestroy(){
  echarts.dispose()
  },
  mounted() {
    this.getmapList();
  },
  methods: {
  async getmapList(){
  const params = {
    areaType: 0,
    areaNames: [
        "国家"
    ],
    timePeriod: 1,
    startDate: "",
    endDate: "",
    targets: [
        {
            "index": "房地产开发投资情况",
            "target": "房地产开发投资额"
        }
    ]
}
      // const loading = this.$loading({
      //     lock: true,
      //     text: 'Loading',
      //     spinner: 'el-icon-loading',
      //     background: 'rgba(0, 0, 0, 0.7)'
      //   });
  this.mapLoading = true;
    let res = await postList(params)
  this.mapLoading = false;

      //  loading.close();

    let arr    = res.map(item=>{

    item.name =  item.area.replace(/省|市|自治区|特别行政区|壮族|回族|维吾尔/g,"")
    // item.area.substr(0, item.area.length - 1);
    // item.name = item.area
    return item;
    })
    this.dataList.map(item=>{
        arr.map(v=>{
        if (item.name==v.name) {
          item.targetValue = v.targetValue
          item.date = v.date
        }
        })
    })

    this.initEchart();

  },
    clickCity(name) {
      this.titleName = name

    },
    changeLeave(num) {
      // this.titleName = name
      this.levelFathser = num

    },

    goback() {
      this.titleName = '国家'
      this.levelFathser = 0
      this.isShowChinaMap = true
      this.initEchart()//这是中国地图方法
    },
   async initEchart() {
      let dataList = this.dataList;

      const _this = this;
      let myChart = this.$echarts.init(document.getElementById(this.echartsId));
      let chinaMapJson = _.cloneDeep(chinaJson)
      echarts.registerMap('china', chinaMapJson)
      // this.$echarts.registerMap('china', chinaJson)
      let option = {
        tooltip: {
          //数据格式化
          backgroundColor: "rgba(255, 255, 255,0.8)",
          borderColor: '#EDF0F4',
          borderWidth: '0.5',
          // position: ['20%', '20%'],
          textStyle: {
            color: '#333333'
          },
          formatter: function (params, callback) {
          if (!params.data.targetValue) {
      return `
            <div style="display: flex; align-items: center;">
        <div style="width: 8px;height: 8px;background-color: #0093DB; border-radius: 50%;margin-right: 30px;"></div>
        <div style="font-size: 14px;
          font-family: Source Han Sans CN-Bold, Source Han Sans CN;
          font-weight: bold;
                color: #333333;">房地产开发投资：一一</div>
      </div>
      `
          }
            return (
              `     <div style="width: 263px;height: ;74px">
      <div style="width: 100%;display: flex; justify-content: space-between; color: #333333;">
        <div style="font-weight: bold;color: #333333;">
        ${params.name}
        </div>
        <div>${params.data.date}</div>
      </div>
      <div style="width: 100%;height: 1px;background:  #EDF0F4; margin-top: 5px;margin-bottom: 5px;"></div>
      <div style="display: flex; align-items: center;">
        <div style="width: 8px;height: 8px;background-color: #0093DB; border-radius: 50%;margin-right: 30px;"></div>
        <div style="font-size: 14px;
          font-family: Source Han Sans CN-Bold, Source Han Sans CN;
          font-weight: bold;
                color: #333333;">房地产开发投资：${params.data.targetValue}亿元</div>
      </div>
    </div>`
    // ${params.data.targetValue}
              // params.seriesName + "<br />" + params.name + "：" + params.value + `<div class="test"></div>`
            );
          },
        },

        geo: {
          map: 'china', //引入地图数据
          roam: true, //不开启缩放和平移
          zoom: 1.1, //视角缩放比例
          label: {
            normal: {
              show: true,
              fontSize: "10",
              color: "#016197",
            },
            emphasis: {
              show: true,
              color: '#ffffff',//文字颜色
            }
          },
          itemStyle: {
            normal: {
              borderWidth: 0.1,
              borderColor:'#000000',
              areaColor: '#e6f1ff',//这是底色
              label: {show:true},
            },
            emphasis: { //高亮的显示设置
              areaColor: "#7bd9ff", //鼠标选择区域颜色
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              shadowBlur: 20,
              borderWidth: 0.1,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
        // 鼠标悬浮提示框
        series: [
          {
            name: "省份",
            type: "map",
            geoIndex: 0,
            data: this.dataList,
          },
        ],
      };
      myChart.setOption(option);

      myChart.on("click", function (params) {
       if(params.data.name=='南海诸岛'){
       return
       }
        _this.isShowChinaMap = false

        _this.provinceName = params.data.name
        _this.levelFathser = 1

        _this.titleName = params.data.name

        // if (!params.data.ename) {
        //   alert('暂无' + params.name + '地图数据');
        //   return;
        // }
        // _this.$router.push({
        //   path: "/province",
        //   query: { provinceName: params.data.ename, province: params.name },
        // });
      });
           myChart.getZr().on('click', function (event) {
        // 没有 target 意味着鼠标/指针不在任何一个图形元素上，它是从“空白处”触发的。
        if (!event.target) {
          myChart.dispatchAction({ type: 'restore' });//点击空白，重置所有
          // 点击在了空白处，做些什么。
        }
      });
      myChart.on("mouseover", function (params) {
      });
    },
    mouseleave() {
      // this.myChart.dispatchAction({ type: 'restore' });
    },
  },

};
</script>
<style lang="scss" scoped>
.toolip {
  width: 370px;
  height: 126px;

  .title {
    display: flex;
    justify-content: space-between;
  }
}

.test {
  color: red;
  background: red;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.map-body {
  padding: 24px 18px;

  .title {

    font-size: 18px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #333333;
    line-height: 24px;
    margin-bottom: 11px;
  }

  .map-flex {
    display: flex;
    // justify-content: space-between;

    .map-box {
      width: 600px;
      height: 600px;
      background: #FFFFFF;
      border-radius: 10px 10px 10px 10px;
      opacity: 1;
      border: 1px solid #E2E2E2;
      margin-right: 20px;
    }

    .echatts-box {
      width: 1030px;
      height: 599px;
      background: #FFFFFF;
      border-radius: 10px 10px 10px 10px;
      opacity: 1;
      border: 1px solid #E2E2E2;
    }
  }
}



.echartsMap {
  height: 595px;
  width: 595px;
  margin: auto;

}

.echartsHistogram {
  height: 450px;
  width: 1020px;
  margin: auto;
}
</style>
