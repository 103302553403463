<template>
    <div>
  <ChinaMap  v-if="chinaMapShow"></ChinaMap>

    </div>
</template>

<script>
	import ChinaMap from '@/views/economics/components/ChinaMap'
import dataReports from "@/mixins/dataReports";

export default {
name: "home",
components: {
			ChinaMap,

		},
    mixins: [dataReports],

    data() {
        return {
        chinaMapShow:true,

        };
    },
    created() {

    },
    mounted() {

    },
    methods: {

    }
};
</script>

<style scoped lang="sass">

</style>
