<template>
  <div v-loading="rightLoading">
    <TabButton :cityName="cityName" :level="level" @emptyEcharts="emptyEcharts" @changeDateType="changeDateType"></TabButton>
    <div :id="echartsHistogramId" class="echartsHistogram"> </div>
  </div>
</template>

<script>
import TabButton from '@/views/economics/components/barlineEcharts/TabButton'
import {
  getTagTree,
  postEcharts
} from "@/api/mapView";
export default {
  components: {
    TabButton,
  },
  props: {
    level:{

    },
    cityName: {
      type: String,

    },
    city:{
      type: String,

    },
  },
  data() {
    return {
    rightLoading: false,
      rotate: 0,//底部文字倾斜角度
      areaType:0,//城市类型
      timePeriod:1,//城市类型
      targets:[//默认指标组
      {
          index: "",
          target: ""
        }
      ],
      myChart:null,
      unit:'',//单位
      echartsHistogramId: 'histogram_' + new Date().getTime() + Math.floor(Math.random() * 1000),
      echatsdataX: ['2021-06', '2021-07', '2021-06', '2021-07', '2021-06', '2021-07', '2021-06', '2021-07', '2021-07', '2021-06', '2021-07', '2021-06',],//x轴数据
      line1Data: [2.0, 2.2, 3.3, 40.5, 6.3, 10.2, 80, 23.4, 23.0, 16.5, 12.0, 6.2],
      seriesList:[],
      legendNameList:[]
    };
  },
  watch: {

    cityName(val,old) {//监听省-市-区-变化
      // switch (val) {
      //   case '国家':
      //     this.areaType = 0;
      //     break;
      //   default:
      //     this.areaType = 1;
      //     break;
      // }
      // this.getTreeData()
    },
    timePeriod(val){
    },
      level(val){
      this.areaType = val
      // this.getTreeData()

  },
  },
  created() {

  },
  mounted() {
    this.getTreeData()
    this.initEchartsHistogram();
  },
  methods: {
    async getTreeData() {
      const params = {
        areaNames: [this.cityName],
        areaType:  this.areaType,
        timePeriod: this.timePeriod,
        targets: this.targets
      }
  // this.rightLoading = true
      let res = await postEcharts(params)
  // this.rightLoading = false
      this.echatsdataX = res.dateList
      let targetDataList = res.targetDataList
      this.unit = targetDataList[0].unit
      let series = [  ]
      let legendName = []
      if(!targetDataList){
    this.legendNameList = []
     this.legendNameList = []
     this.seriesList = [
     {
          name: '',
          type: 'bar',
          yAxisIndex: 0,
          data: []
        }
      ]
    this.echatsdataX  = [ ]
      this.initEchartsHistogram()
        // this.mychart.clear();
return
      }
      this.myChart.clear()
      targetDataList.map(item => {
  let str = '同比增长'
    if (targetDataList.length==2) {
  str =item.target + str
    }
    legendName.push(item.target,str)
        let valueObj = {
          name: item.target,
          type: 'bar',
          yAxisIndex: 0,
          data: item.value
        }
        let yoyObj = {
          name: str,
          type: 'line',
          yAxisIndex: 1,
          smooth: true,//曲线
          data:  item.yoy
        }
        series.push(valueObj, yoyObj)
      })
      this.seriesList = series
      this.legendNameList = legendName
      // this.line1Data = this.line1Data.map(item => {
      //   item = Math.ceil(Math.random() * 1000 - 1)
      //   return item
      // })
      this.initEchartsHistogram()

    },
    emptyEcharts(){//无tab数据
        this.legendNameList = []
     this.legendNameList = []
     this.seriesList = [
     {
          name: '',
          type: 'bar',
          yAxisIndex: 0,
          data: []
        }
      ]
    this.echatsdataX  = [ ]
      this.initEchartsHistogram()
    },
    changeDateType(dateType, indicator) {
    this.targets = indicator
    switch (dateType) {
      case '日度':
      this.timePeriod = 0
        break;
      case '月度':
      this.timePeriod = 1
        break;
      case '季度':
      this.timePeriod = 2
        break;
      case '年度':
      this.timePeriod = 3
        break;

      default:  this.timePeriod = 1
        break;
    }

      if (dateType == '季度') {
        this.rotate = 60}else{
         this.rotate = 0
        }

      this.getTreeData()

      // if (dateType == '季度') {
      //   this.rotate = 60
      //   this.echatsdataX = [
      //     '2019年第一季度',
      //     '2019年第二季度',
      //     '2019年第一季度',
      //     '2019年第二季度',
      //     '2019年第一季度',
      //     '2019年第二季度',
      //     '2019年第一季度',
      //     '2019年第二季度',
      //     '2019年第一季度',
      //     '2019年第二季度',
      //     '2019年第一季度',
      //     '2019年第二季度',
      //   ];
      // } else {
      //   this.rotate = 0;
      //   this.echatsdataX = ['2021-06', '2021-07', '2021-06', '2021-07', '2021-06', '2021-07', '2021-06', '2021-07', '2021-07', '2021-06', '2021-07', '2021-06',]
      // }
    },
    initEchartsHistogram() {//图表
      this.myChart = this.$echarts.init(document.getElementById(this.echartsHistogramId));
      // const colors = ['#0093DB', '#68D9BE', '#FFC700','#EA6159'];
      const colors = ['#0093DB', '#FFC700', '#68D9BE', '#EA6159'];
      let option = {
        color: colors,
        tooltip: {
          trigger: 'axis',
          backgroundColor: "rgba(255, 255, 255,0.8)",
          borderColor: '#EDF0F4',
          borderWidth: '0.5',
          // position: ['20%', '20%'],
          textStyle: {
            color: '#333333'
          },
          // formatter:'{b0}: {c0}<br />{b1}: {c1}',
        },
        grid: {
          // right: 0,
          // top: '0%',
          bottom: '20%',
        },

        legend: {
          right: '7%',
          top: '0',
          itemGap: 50,
          data: this.legendNameList
        },
        xAxis: [
          {
            type: 'category',
            // show: false,
            splitLine: {
              show: true,
              lineStyle: {
                color: '#f0f0f0',
                width: 1,
                type: 'solid'
              }
            },
            axisTick: {
              alignWithLabel: false
            },
            // prettier-ignore
            // nameRotate:90,
            axisLabel: {
              rotate: this.rotate,//倾斜角度
              margin: 10, //刻度标签与轴线之间的距离

            },


            data: this.echatsdataX
          }
        ],
        yAxis: [

          {
            type: 'value',
            name: this.unit,
            position: 'left',
            alignTicks: false,
            // offset: 30,
            axisLine: {
              show: false,
              lineStyle: {
                // color: colors[0]
              }
            },
            axisLabel: {
              formatter: '{value}'
            }
          },
          {
            type: 'value',
            name: '%',
            position: 'right',
            alignTicks: false,
            axisLine: {
              show: false,
              // lineStyle: {
              //   color: colors[1]
              // }
            },
            axisLabel: {
              formatter: '{value}'
            }
          }
        ],
        series:this.seriesList
        // series: [

        //   {
        //     name: '房地产开发投资',
        //     type: 'bar',
        //     yAxisIndex: 0,
        //     data: [
        //       2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 1000, 182.2, 48.7, 18.8, 6.0, 2.3
        //     ]
        //   },
        //   {
        //     name: '测试',
        //     type: 'bar',
        //     yAxisIndex: 0,
        //     data: [
        //       2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 1000, 182.2, 48.7, 18.8, 6.0, 2.3
        //     ]
        //   },
        //   {
        //     name: '同比增长',
        //     type: 'line',
        //     yAxisIndex: 1,
        //     smooth: true,//曲线
        //     data: this.line1Data
        //   },
        //   {
        //     name: '测试2',
        //     type: 'line',
        //     yAxisIndex: 1,
        //     smooth: true,//曲线
        //     data: [2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 80, 23.4, 23.0, 16.5, 12.0, 6.2]
        //   },
        // ]
      };
      this.myChart.setOption(option,true);
    },
  }
};
</script>

<style scoped lang="scss">
.echartsHistogram {
  height: 480px;
  width: 1020px;
  margin: auto;
  margin-bottom: 20px;
}
</style>
