<template>
  <div>
    <div class="back" @click="goBack()">
      <Icon type="ios-arrow-dropleft" class="icon" size="20" />
    </div>
    <div :id="id" @mouseleave="mouseleave" class="provinceCharts"></div>
  </div>
</template>

<script>
// import cityMap from "../../../public/json/map/china-main-city-map.js";
import cityMap from "../../../../public/json/map/china-main-city-map.js";
import {
  postList
} from "@/api/mapView";
export default {

  props: {
    provinceNames: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      id: 'echarts_' + new Date().getTime() + Math.floor(Math.random() * 1000),
      echartObj: null,
      isClickCity:false,
      showCity: [//显示的城市数据

      ],
      dataList:[],
      cityColorList: [],
      cityName: null,
      emphasisOptions: {
        show: true,
        areaColor: '#7bd9ff', // 鼠标选择区域颜色
        // areaColor: '#e6f1ff', // 鼠标选择区域颜色

        shadowOffsetX: 0,
        shadowOffsetY: 0,
        // shadowBlur: 20,
        // borderWidth: 0,
        // shadowColor: 'rgba(0, 0, 0, 0.5)',
      },



    }
  },
  mounted() {
this.getmapList()

  },
  methods: {
    async getmapList(){
  const params = {
    areaType: 1,
    areaNames: [
        this.provinceNames
    ],
    timePeriod: 1,
    startDate: "",
    endDate: "",
    targets: [
        {
            "index": "房地产开发投资情况",
            "target": "房地产开发投资额"
        }
    ]
}
    let res = await postList(params)
    let arr = []

    res.map(item=>{
    arr.push(item.area)
    item.name =item.area
    })
    this.dataList = res
    this.showCity = arr
    this.cityColor()
    this.initDate();
    this.resizeListener();




  },
   cityColor(){
   let arr = []
  this.showCity.map(item=>{
  let obj = {//特定区域格式调整
            name: item,
            itemStyle: {
            emphasis: {
              areaColor: '#7bd9ff', // 鼠标选择区域颜色
            }
          },
          }
          arr.push(obj)

  })
  this.cityColorList = arr
   },
    mouseleave() {
      //  this.echartObj.dispatchAction({ type: 'restore' });//重置所有
    },

    setOption(name) {

    },
    initDate() {
      let that = this
      const provinceName = this.$route.query.provinceName
      const province = this.$route.query.province
      this.provinceName = provinceName

      // this.provinceJSON = require('../../utils/省份数据/json(省份)/' + provinceName)
      let cityId = cityMap[this.provinceNames];
      this.provinceJSON = require(' ../../../public/json/map/' + cityId)
      // this.provinceJSON = require(' echarts/map/json/province' + this.provinceNames)
      this.setOption(this.provinceNames)
      const option = {
        title: {
          text: '',
          top: '8%',
          left: '8%',
          textStyle: {
            fontSize: 14,
            fontWeight: 300,
            color: '#000'
          }
        },

        tooltip: {
          padding: 5,
          backgroundColor: "rgba(255, 255, 255,0.8)",
          borderColor: '#EDF0F4',
          borderWidth: '0.5',
          // position: ['20%', '20%'],
          textStyle: {
            color: '#333333'
          },
          // show:false,
          // 数据格式化
          formatter: function (params, callback) {
            //  return that.showCity.indexOf(params.name)==-1?'':params.name;//某些城市显示

            if (that.showCity.indexOf(params.name) == -1) {
              return ''
            } else {
           if (!params.data.targetValue){
return  `
            <div style="display: flex; align-items: center;">
        <div style="width: 8px;height: 8px;background-color: #0093DB; border-radius: 50%;margin-right: 30px;"></div>
        <div style="font-size: 14px;
          font-family: Source Han Sans CN-Bold, Source Han Sans CN;
          font-weight: bold;
                color: #333333;">房地产开发投资：一一</div>
      </div>
      `
           }else{
 return `    <div style="width: 263px;height: ;74px">
      <div style="width: 100%;display: flex; justify-content: space-between; color: #333333;">
        <div style="font-weight: bold;color: #333333;">
          ${params.name}
        </div>
        <div>${params.data.date}</div>
      </div>
      <div style="width: 100%;height: 1px;background:  #EDF0F4; margin-top: 5px;margin-bottom: 5px;"></div>
      <div style="display: flex; align-items: center;">
        <div style="width: 10px;height: 10px;background-color: #0093DB; border-radius: 50%;margin-right: 30px;"></div>
        <div style="font-size: 14px;
          font-family: Source Han Sans CN-Bold, Source Han Sans CN;
          font-weight: bold;
                color: #333333;">房地产开发投资：${params.data.targetValue}亿元</div>
      </div>
    </div>`
           }


            }


          }
        },
        legend: {
          orient: 'vertical',
          top: '9%',
          left: '5%',
          icon: 'circle',
          data: [],
          // selectedMode: 'single',
          selected: {},
          itemWidth: 12,
          itemHeight: 12,
          itemGap: 30,
          inactiveColor: '#b6d7ff',
          textStyle: {
            color: '#ec808d',
            fontSize: 14,
            fontWeight: 300,
            padding: [0, 0, 0, 15]
          }
        },
        visualMap: {
          min: 0,
          max: 500,
          left: 'left',
          top: 'bottom',
          text: ['高', '低'], // 取值范围的文字
          inRange: {
            color: ["#e0ffff", "blue"], //取值范围的颜色
          },
          show: false // 图注
        },
        geo: {
          map: '',
          silent: false,//响应鼠标事件
          roam: true, // 不开启缩放和平移
          selectedMode: 'single',
          //  selectedMode: false,//禁止点击
          zoom: 1, // 视角缩放比例
          emphasis: {
            show: false,
            // disabled:true//禁止高亮
          },
          regions:this.cityColorList //特定区域格式调整

            ,
          select: {
            // disabled: true,
            itemStyle: {
              areaColor: "#fff",
              color: "#fff",
            }
          },
          label: {
            normal: {
              // show: true,//显示文字
              show: true,
              formatter(params) {
                //  return params.name=='漳州市'?'漳州市':'';
                return that.showCity.indexOf(params.name) == -1 ? '' : params.name;//某些城市显示
              },
              color: '#016197',
              fontSize: 12,
            },
            emphasis: {
              show: true,
              color: '#ffffff',//文字颜色
            }
          },
          //  select:{//选中状态修改
          // itemStyle:{
          // areaColor:"black",
          // }
          // },
          itemStyle: {
            normal: {
              borderColor: 'rgba(255,255,255)',
              areaColor: '#e6f1ff',//这是底色
              // borderWidth:1,
              // borderType:'dotted'
            },
            emphasis: {
              show: true,
              // areaColor: '#7bd9ff', // 鼠标选择区域颜色
              areaColor: '#e6f1ff', // 鼠标选择区域颜色

              shadowOffsetX: 0,
              shadowOffsetY: 0,
              // shadowBlur: 20,
              // borderWidth: 0,
              // shadowColor: 'rgba(0, 0, 0, 0.5)',
            }
          },
          left: '5%',
          right: '5%',
          top: '5%',
          bottom: '5%'
        },
        series: [
          {
            name: '省份数据',
            type: 'map',
            geoIndex: 0, // 不可缺少，否则无tooltip 指示效果
            data: this.dataList
          },
        ],
        provinceJSON: {},
        provinceName: ''
      }
      option.title.text = province;
      option.geo.map = province
      this.echartObj = this.$echarts.init(document.getElementById(this.id))
      this.$echarts.registerMap(province, this.provinceJSON)

      this.echartObj.setOption(option);
      // this.echartObj.dispatchAction({ type: 'highlight', name: "漳州市" }); // dataIndex：默认选中项索引值
      // this.echartObj.dispatchAction({ type: 'tooltip', name: "广州市" ,hideTip}); // dataIndex：默认选中项索引值
      this.echartObj.getZr().on('click', function (event) {
        // 没有 target 意味着鼠标/指针不在任何一个图形元素上，它是从“空白处”触发的。
        if (!event.target) {
          that.$emit('clickCity', that.provinceNames)
          that.$emit('changeLeave',1)
          that.cityName = ''
          // that.echartObj.dispatchAction({ type: 'downplay', name: that.cityName });//这是取消高亮，非取消选中
          that.echartObj.dispatchAction({ type: 'restore' });//点击空白，重置所有

          // 点击在了空白处，做些什么。
        }
      });
      this.echartObj.on("click", (params) => {
        let isSelected = params;
        if (that.cityName == params.name) {
         that.cityName = ''
        that.$emit('clickCity', that.provinceNames)
           that.$emit('changeLeave',1)
          // that.echartObj.dispatchAction({ type: 'restore' });//点击空白，重置所有
          // that.$emit('clickCity', that.provinceNames)

          // that.isClickCity = !that.isClickCity
          return
        }
        if (that.showCity.indexOf(params.name) !== -1) {
        that.cityName = params.name
          this.isClickCity = true;
           that.$emit('changeLeave',2)
          that.$emit('clickCity', that.cityName)

        }else{
          that.echartObj.dispatchAction({ type: 'downplay', name: params.name });//这是取消高亮，非取消选中
        if (that.cityName) {
      this.echartObj.dispatchAction({ type: 'highlight', name: that.cityName }); // dataIndex：默认选中项索引值

        }

        }


      });
      this.echartObj.on("mouseover", (params) => {
        if (that.showCity.indexOf(params.name) !== -1) {//数组中有这个个城市
          //  option.geo.selectedMode = 'single'
          //  option.geo.silent = true

          // that.echartObj.setOption(option);

        } else {

          // that.echartObj.dispatchAction({ type: 'downplay',  });//这是取消高亮，非取消选中
          // option.geo.selectedMode = false
          // option.geo.silent = true
          // that.echartObj.setOption(option);
          // that.echartObj.dispatchAction({ type: 'highlight', name: that.cityName}); // dataIndex：默认选中项索引值

        }
        // that.echartObj.setOption(option);



      });
      this.echartObj.on("mouseout", (params) => {//鼠标移开
        if (that.showCity.indexOf(params.name) == -1) {
          // that.echartObj.dispatchAction({ type: 'downplay', name:params.name });//这是取消高亮，非取消选中
          // option.geo.selectedMode = 'single'
          //   that.echartObj.setOption(option);
        }

      });



    },
    resizeListener() {
      window.addEventListener('resize', () => {
        if (this.echartObj && this.echartObj.resize) {
          this.echartObj.resize()
        }
      })
    },
    goBack() {
      this.$emit('back',)
    },
  }
}
</script>
<style lang="scss" scoped>
.back {
  width: 36px;
  height: 36px;
  background: #FFFFFF;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #EDEDED;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 18px;
  margin-top: 18px;
  position: absolute;
  z-index: 9;

  .icon {
    font-weight: 600;
    // margin: auto;
  }
}

.provinceCharts {
  height: 600px;
  width: 600px;
  margin: auto;
}
</style>
